<script>
/**
 * Revenue Analytics component
 */
export default {
  props: ['data'],
  data() {
    return {
      obj_this_year:{
        jan: this.data.this_year.January?this.data.this_year.January:0,
        feb: this.data.this_year.Febuary?this.data.this_year.Febuary:0,
        march: this.data.this_year.March?this.data.this_year.March:0,
        apr: this.data.this_year.April?this.data.this_year.April:0,
        may: this.data.this_year.May?this.data.this_year.May:0,
        jun: this.data.this_year.June?this.data.this_year.June:0,
        jul: this.data.this_year.July?this.data.this_year.July:0,
        aug: this.data.this_year.August?this.data.this_year.August:0,
        sept: this.data.this_year.September?this.data.this_year.September:0,
        oct: this.data.this_year.October?this.data.this_year.October:0,
        nov: this.data.this_year.November?this.data.this_year.November:0,
        dec: this.data.this_year.December?this.data.this_year.December:0,
      },
      obj_last_year:{
        jan: this.data.last_year.January?this.data.last_year.January:0,
        feb: this.data.last_year.Febuary?this.data.last_year.Febuary:0,
        march: this.data.last_year.March?this.data.last_year.March:0,
        apr: this.data.last_year.April?this.data.last_year.April:0,
        may: this.data.last_year.May?this.data.last_year.May:0,
        jun: this.data.last_year.June?this.data.last_year.June:0,
        jul: this.data.last_year.July?this.data.last_year.July:0,
        aug: this.data.last_year.August?this.data.last_year.August:0,
        sept: this.data.last_year.September?this.data.last_year.September:0,
        oct: this.data.last_year.October?this.data.last_year.October:0,
        nov: this.data.last_year.November?this.data.last_year.November:0,
        dec: this.data.last_year.December?this.data.last_year.December:0,
      },
      series: [
        {
          name: "This Year",
          type: "column",
          data: [this.data.this_year.January?this.data.this_year.January:0, this.data.this_year.Febuary?this.data.this_year.Febuary:0, this.data.this_year.March?this.data.this_year.March:0, this.data.this_year.April?this.data.this_year.April:0, this.data.this_year.May?this.data.this_year.May:0, this.data.this_year.June?this.data.this_year.June:0, this.data.this_year.July?this.data.this_year.July:0, this.data.this_year.August?this.data.this_year.August:0, this.data.this_year.September?this.data.this_year.September:0, this.data.this_year.October?this.data.this_year.October:0, this.data.this_year.November?this.data.this_year.November:0, this.data.this_year.December?this.data.this_year.December:0]
        },
        {
          name: "Last Year",
          type: "line",
          data: [this.data.last_year.January?this.data.last_year.January:0, this.data.last_year.Febuary?this.data.last_year.Febuary:0, this.data.last_year.March?this.data.last_year.March:0, this.data.last_year.April?this.data.last_year.April:0, this.data.last_year.May?this.data.last_year.May:0, this.data.last_year.June?this.data.last_year.June:0, this.data.last_year.July?this.data.last_year.July:0, this.data.last_year.August?this.data.last_year.August:0, this.data.last_year.September?this.data.last_year.September:0, this.data.last_year.October?this.data.last_year.October:0, this.data.last_year.November?this.data.last_year.November:0, this.data.last_year.December?this.data.last_year.December:0]
        }
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  },

  mounted() {
    console.log(this.obj_last_year)
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right d-none d-md-inline-block">
        <div class="btn-group mb-2">
          <!--<button type="button" class="btn btn-sm btn-light">Today</button>
          <button type="button" class="btn btn-sm btn-light active">Weekly</button>-->
          <button type="button" class="btn btn-sm btn-light">Monthly</button>
        </div>
      </div>
      <h4 class="card-title mb-4">PT Rounds</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <!--<div class="col-sm-4">
          <div class="d-inline-flex">
            <h5 class="mr-2">12,253</h5>
            <div class="text-success">
              <i class="mdi mdi-menu-up font-size-14"></i>2.2 %
            </div>
          </div>
          <p class="text-muted text-truncate mb-0">This month</p>
        </div>-->

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">{{obj_this_year.jan+obj_this_year.feb+obj_this_year.march+obj_this_year.apr+obj_this_year.may+obj_this_year.jun+obj_this_year.jul+obj_this_year.aug+obj_this_year.sept+obj_this_year.oct+obj_this_year.nov+obj_this_year.dec}}</h5>
              <div v-if="(obj_this_year.jan+obj_this_year.feb+obj_this_year.march+obj_this_year.apr+obj_this_year.may+obj_this_year.jun+obj_this_year.jul+obj_this_year.aug+obj_this_year.sept+obj_this_year.oct+obj_this_year.nov+obj_this_year.dec - (obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec)) >= 0" class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>{{obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec > 0 ?
                (obj_this_year.jan+obj_this_year.feb+obj_this_year.march+obj_this_year.apr+obj_this_year.may+obj_this_year.jun+obj_this_year.jul+obj_this_year.aug+obj_this_year.sept+obj_this_year.oct+obj_this_year.nov+obj_this_year.dec - (obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec))/(obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec) : 0}}
              </div>
              <div v-else class="text-danger">
                <i class="mdi mdi-menu-down font-size-14"></i>{{obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec > 0 ?
                (obj_this_year.jan+obj_this_year.feb+obj_this_year.march+obj_this_year.apr+obj_this_year.may+obj_this_year.jun+obj_this_year.jul+obj_this_year.aug+obj_this_year.sept+obj_this_year.oct+obj_this_year.nov+obj_this_year.dec - (obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec))/(obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec) : 0}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">{{obj_last_year.jan+obj_last_year.feb+obj_last_year.march+obj_last_year.apr+obj_last_year.may+obj_last_year.jun+obj_last_year.jul+obj_last_year.aug+obj_last_year.sept+obj_last_year.oct+obj_last_year.nov+obj_last_year.dec}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>