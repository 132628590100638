<script>
/**
 * Widget Component
 */
export default {
  props: ['numbers'],
  data() {
    return {
      number: this.numbers,
      statData: [
        {
          title: "Number of EID POCT",
          icon: "ri-stack-line",
          value: this.numbers.count_eid,
          subvalue: Math.round(this.numbers.eid_percentage)
        },
        {
          title: "Number of VL POCT",
          icon: "ri-store-2-line",
          value: this.numbers.count_vl,
          subvalue: Math.round(this.numbers.vl_percentage)
        },
        {
          title: "Number of PT Round",
          icon: "ri-briefcase-4-line",
          value: parseFloat(this.numbers.total),
          subvalue:Math.round(this.numbers.total_percentage)
        }
      ]
    };
  },
  mounted() {
    console.log(this.number)
    console.log(parseFloat(this.numbers.eid_percentage) > 0)
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span v-if="parseFloat(data.subvalue) >= 0" class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}} %
            </span>
            <span v-else class="badge badge-soft-danger font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}} %
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>