<script>
/**
 * Transactions component
 */
export default {
  props: ['data'],
  data() {
    return {
      sites: this.data ? this.data.map(e=>{e.enrollment_status=e.enrollment_status.split('T')[0]; e.name = e.region.name; return e}): {},
      transactionData: [
        {
          PTID: 'CMR-EIDPOC-0001',
          region: 'Littoral',
          location: 'Douala',
          enrollmentStatus: 2018,
          contactPerson: '675636363',
          telephone: '675636363',
          email: 'joel@admin.com',
          facilityEmail:'joe@admin.com',
          typeOfFacility:'Confessional',
        },
        {
          PTID: 'CMR-EIDPOC-0002',
          region: 'Centre',
          location: 'Bafia',
          enrollmentStatus: 2018,
          contactPerson: '675636363',
          telephone: '675636363',
          email: 'joel@admin.com',
          facilityEmail:'joe@admin.com',
          typeOfFacility:'Confessional',
        },
        {
          PTID: 'CMR-EIDPOC-0002',
          region: 'Centre',
          location: 'Bafia',
          enrollmentStatus: 2018,
          contactPerson: '675636363',
          telephone: '675636363',
          email: 'joel@admin.com',
          facilityEmail:'joe@admin.com',
          typeOfFacility:'Confessional',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "ptid", sortable: true },
        //{ key: "name", label: "Region", sortable: true },
        { key: "location", sortable: true },
        { key: "enrollment_status", sortable: true },
        { key: "contact_person", sortable: true },
        { key: "telephone", sortable: true },
        // { key: "email", sortable: true },
        // { key: "facilityEmail", sortable: true },
        { key: "type_facility", sortable: true },
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <!-- item-->
<!--        <b-dropdown-item>Sales Report</b-dropdown-item>-->
        <!-- item-->
        <b-dropdown-item>Export Report</b-dropdown-item>
        <!-- item-->
<!--        <b-dropdown-item>Profit</b-dropdown-item>-->
<!--        &lt;!&ndash; item&ndash;&gt;-->
<!--        <b-dropdown-item>Action</b-dropdown-item>-->
      </b-dropdown>

      <h4 class="card-title mb-4">Latest Sites</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>

<!--      <div class="table-responsive">-->
<!--        <b-table-->
<!--          :items="transactionData"-->
<!--          :fields="fields"-->
<!--          responsive="sm"-->
<!--          :per-page="perPage"-->
<!--          :current-page="currentPage"-->
<!--          :sort-by.sync="sortBy"-->
<!--          :sort-desc.sync="sortDesc"-->
<!--          :filter="filter"-->
<!--          :filter-included-fields="filterOn"-->
<!--          @filtered="onFiltered"-->
<!--        >-->
<!--          <template v-slot:cell(paymentstatus)="row">-->
<!--            <div-->
<!--              class="badge font-size-12"-->
<!--              :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',-->
<!--              'badge-soft-success': `${row.value}` === 'Paid',-->
<!--              'badge-soft-warning': `${row.value}` === 'Unpaid'}"-->
<!--            >{{ row.value}}</div>-->
<!--          </template>-->

<!--          <template v-slot:cell(action)>-->
<!--            <a-->
<!--              href="javascript:void(0);"-->
<!--              class="mr-3 text-primary"-->
<!--              v-b-tooltip.hover-->
<!--              data-toggle="tooltip"-->
<!--              title="Edit"-->
<!--            >-->
<!--              <i class="mdi mdi-pencil font-size-18"></i>-->
<!--            </a>-->
<!--            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">-->
<!--              <i class="mdi mdi-trash-can font-size-18"></i>-->
<!--            </a>-->
<!--          </template>-->
<!--        </b-table>-->
<!--      </div>-->
      <div class="table-responsive mb-0">
        <b-table
                :items="sites"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"

                @filtered="onFiltered"
        >
        </b-table>
      </div>

      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>