<script>
/**
 * Sales Analytics component
 */
export default {
  props: ['type_facility'],
  data() {
    return {
      type_of_facility: this.type_facility,
      obj_eid: {
        confessionnal : this.type_facility.eid.Confessional?this.type_facility.eid.Confessional:0,
        faith : this.type_facility.eid['Faith based']?this.type_facility.eid['Faith based']:0,
        parastatal : this.type_facility.eid.Parastatal?this.type_facility.eid.Parastatal:0,
        public : this.type_facility.eid.Public?this.type_facility.eid.Public:0,
        ngo : this.type_facility.eid.NGO?this.type_facility.eid.NGO:0
      },
      obj_vl: {
        confessionnal : this.type_facility.vl.Confessional?this.type_facility.vl.Confessional:0,
        faith : this.type_facility.vl['Faith based']?this.type_facility.vl['Faith based']:0,
        parastatal : this.type_facility.vl.Parastatal?this.type_facility.vl.Parastatal:0,
        public : this.type_facility.vl.Public?this.type_facility.vl.Public:0,
        ngo : this.type_facility.vl.NGO?this.type_facility.vl.NGO:0
      },
      //somme_eid : this.obj_eid ? this.obj_eid.confessionnal+this.obj_eid.faith+this.obj_eid.parastatal+this.obj_eid.public+this.obj_eid.ngo : 0,
      series_eid: [this.type_facility.eid.Confessional?this.type_facility.eid.Confessional:0,  this.type_facility.eid['Faith based']?this.type_facility.eid['Faith based']:0, this.type_facility.eid.Parastatal?this.type_facility.eid.Parastatal:0, this.type_facility.eid.Public?this.type_facility.eid.Public:0, this.type_facility.eid.NGO?this.type_facility.eid.NGO:0],
      series_vl: [this.type_facility.vl.Confessional?this.type_facility.vl.Confessional:0,  this.type_facility.vl['Faith based']?this.type_facility.vl['Faith based']:0, this.type_facility.vl.Parastatal?this.type_facility.vl.Parastatal:0, this.type_facility.vl.Public?this.type_facility.vl.Public:0, this.type_facility.vl.NGO?this.type_facility.vl.NGO:0],
      //series_vl: [42, 26, 15, 26, 15],
      select: 0,
      chartOptions: {
        chart: {
          height: 230,
          type: "donut"
        },
        labels: ["Confessional", "Faith based", "Parastatal", "Public", "Ngo"],
        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902", "#343a40", "#D20E13"]
      }
    };
  },

  mounted(){
    console.log(this.obj_vl)
    console.log(this.type_facility)
  },

  methods:{
    choose(e){
      if (e=== 'vl'){
        this.select =1
      }else{
        this.select =0
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!--<div class="float-right">
        <select class="custom-select custom-select-sm">
          <option selected>Apr</option>
          <option value="1">Mar</option>
          <option value="2">Feb</option>
          <option value="3">Jan</option>
        </select>
      </div>-->
<!--      <h4 class="card-title mb-4">EID Analytics</h4>-->
      <div class="btn-group mb-2">
        <button type="button" :class="select ? 'btn btn-sm btn-light' : 'btn btn-sm btn-light active'" @click="choose('eid')">EID Analytics</button>
        <button type="button" :class="!select ? 'btn btn-sm btn-light' : 'btn btn-sm btn-light active'" @click="choose('vl')">VL Analytics</button>
      </div>

      <div id="donut-chart" class="apex-charts"></div>
      <apexchart
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="select ?[this.type_facility.vl.Confessional&&this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith>0?(Math.round(this.type_facility.vl.Confessional*100/(this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith))/100):0,  this.type_facility.vl['Faith based']&&this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith>0?Math.round(this.type_facility.vl['Faith based']*100/(this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith))/100:0, this.type_facility.vl.Parastatal&&this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith>0?Math.round(this.type_facility.vl.Parastatal*100/(this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith))/100:0, this.type_facility.vl.Public&&this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith>0?Math.round(this.type_facility.vl.Public*100/(this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith))/100:0, this.type_facility.vl.NGO&&this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith>0?Math.round(this.type_facility.vl.NGO*100/(this.obj_vl.public+this.obj_vl.parastatal+this.obj_vl.confessionnal+this.obj_vl.ngo+this.obj_vl.faith))/100:0] :
        [this.type_facility.eid.Confessional&&this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith>0?(Math.round(this.type_facility.eid.Confessional*100/(this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith))/100):0,  this.type_facility.eid['Faith based']&&this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith>0?Math.round(this.type_facility.eid['Faith based']*100/(this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith))/100:0, this.type_facility.eid.Parastatal&&this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith>0?Math.round(this.type_facility.eid.Parastatal*100/(this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith))/100:0, this.type_facility.eid.Public&&this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith>0?Math.round(this.type_facility.eid.Public*100/(this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith))/100:0, this.type_facility.eid.NGO&&this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith>0?Math.round(this.type_facility.eid.NGO*100/(this.obj_eid.public+this.obj_eid.parastatal+this.obj_eid.confessionnal+this.obj_eid.ngo+this.obj_eid.faith))/100:0]"
        :options="chartOptions"
      ></apexchart>
      <div class="row">
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> Confessional
            </p>
            <h5>{{!select ? type_of_facility.eid.Confessional? type_of_facility.eid.Confessional : 0:type_of_facility.vl.Confessional?type_of_facility.vl.Confessional: 0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Faith based
            </p>
            <h5>{{!select ? type_of_facility.eid['Faith based']? type_of_facility.eid['Faith based']: 0:type_of_facility.vl['Faith based']?type_of_facility.vl['Faith based']: 0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Parastatal
            </p>
            <h5>{{!select ? type_of_facility.eid.Parastatal? type_of_facility.eid.Parastatal :0: type_of_facility.vl.Parastatal?type_of_facility.vl.Parastatal : 0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-dark font-size-10 mr-1"></i> Public
            </p>
            <h5>{{!select ? type_of_facility.eid.Public? type_of_facility.eid.Public:0:type_of_facility.vl.Public?type_of_facility.vl.Public : 0}}</h5>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-danger font-size-10 mr-1"></i> NGO
            </p>
            <h5>{{!select ? type_of_facility.eid.NGO? type_of_facility.eid.NGO:0 :type_of_facility.vl.NGO?type_of_facility.vl.NGO : 0}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>