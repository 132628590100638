<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import EarningReport from "./earning";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
// import Chat from "./chat";
import Transaction from './transaction';
import {axios_get} from "../../../helpers/helper";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    SalesAnalytics,
    EarningReport,
    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // Chat,
    Transaction
  },
  data() {
    return {
      title: "Dashboard",
      numbers: null,
      tracking_info: null,
      type_facility: null,
      ptrounds: null,
      sites: null,
      items: [
        {
          text: "CQI"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },

  mounted(){
    const user = JSON.parse(localStorage.getItem("user"))
    console.log(user)
    axios_get('/stats?id='+user.country_id,{}, (res)=>{
      this.type_facility = {"eid" : res.eid_facility_types, "vl": res.vl_facility_types}
      this.numbers = res.numbers
      this.sites = res.sites
      this.ptrounds = {"last_year" : res.ptrounds_by_month_past, "this_year" : res.ptrounds_by_month}
      this.tracking_info = res.tracking_report
      console.log(res)
    }, (err)=>{
      console.log(err)
    })
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat v-if="numbers" :numbers="numbers"/>
        <RevenueAnalytics v-if="ptrounds" :data="ptrounds"/>
      </div>
      <div class="col-xl-4">
        <SalesAnalytics v-if="type_facility" :type_facility="type_facility"/>
        <EarningReport v-if="tracking_info" :data="tracking_info" />
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-lg-4">-->
<!--        <Sources />-->
<!--      </div>-->
<!--      <div class="col-lg-4">-->
<!--        <RecentActivity />-->
<!--      </div>-->
<!--      <div class="col-lg-4">-->
<!--        <RevenueLocation />-->
<!--      </div>-->
<!--    </div>-->
    <div class="row">
<!--      <div class="col-lg-4">-->
<!--        <Chat />-->
<!--      </div>-->
      <div class="col-lg-12">
        <Transaction v-if="sites" :data="sites"/>
      </div>
    </div>
  </Layout>
</template>